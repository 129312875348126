import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Roles } from './enum/UserRoles';
 
@Injectable()
export class AuthGuardService implements CanActivate  {

  routerLink:any;
 
  constructor(
    private auth: AuthService, 
    private router: Router,
    ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> |Promise<boolean> | boolean {     

    // //console.log('CurrentRoutes',this.currentRoutes);
    // //console.log('RouteConfig',route.routeConfig, route.routeConfig.path);
    const allowedRoles = next.data['roles'] as Roles[];
    this.auth.setReturnUrl(state.url);
 
  if(!this.auth.getToken())
  {
    this.auth.logout();
    return false;
  }

 
  if(!this.auth.isAuthorized(allowedRoles))
  {
    this.auth.showUnAuthorizeMessage();
    this.auth.logout();
    return false;
  }

  return true;
 
    // if( (route.routeConfig.path == 'dashboard') || (route.routeConfig.path == 'dashboard-technician')
    //  || (route.routeConfig.path == 'dashboard-customer'))
    // {
    //   this.auth.setReturnUrl(state.url);
    //   this.routerLink = route?.routeConfig?.path;
    // }
    //  else
    //   {
    //   this.auth.clearReturnUrl();
    //   //console.log(route.routeConfig.data.title)
    //   this.routerLink = route?.routeConfig?.data?.title;
    // }   

    // this.auth.login();
    // return true;





    // return this.auth.isAuthenticated(this.routerLink)
    // return this.auth.isAuthenticated(state.url)

  // console.log("active route " + state.url +'  '+ this._validateRouteSVC.isValidRoute(state.url))

   // Below Code commented use for HealthCare Not in use for StaffWorks -> Vivek Chauhan [31-Dec-2024]

  // return this.auth.isAuthenticated(this.routerLink)
  //     .then(
  //       (authenticated: boolean) => {          
  //         if(authenticated) {
  //           this.auth.login();
  //           return true;
  //         } else {
  //           this.route.navigate(['/']);
  //           return false;
  //         }
  //       }
  //     );
  }
}
