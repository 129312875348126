<div class="col-md-12">
<div class="row">
  <div class="col-md-6 align-self-center">
    <h6 class="dialog-header pt-2">Send Notification</h6>
  </div>
  <div class="col-md-6">
    <div class="row justify-content-end">
      <button class="btn-close cursor-pointer" (click)="closePopup()"></button>
    </div>
  </div>
</div>
<div class="row">
  <mat-form-field appearance="outline" floatLabel="always" class="example-full-width custom-search">
    <mat-label>Distribution</mat-label>
    <mat-select [(ngModel)]="selectedDistribution" multiple>
      <mat-option>
        <ngx-mat-select-search [placeholderLabel]="'Search Here...'" [noEntriesFoundLabel]="'Record not found'"
          [formControl]="distributionSeachInput"></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let option of distributionList_Filter" [value]="option.distributionCode">
        {{option.distributionName | capitalize}}
      </mat-option>
    </mat-select>

  </mat-form-field>
</div>

<div class="row">
  <div class="col-lg-12 text-center">
    <p><strong>OR</strong></p>
  </div>
</div>

<div class="row mt-3 spinner">
  <ng-container *ngIf="eligibleStaffsList.length > 0; else noData">
    <mat-form-field appearance="outline" floatLabel="always" class="example-full-width custom-search">
      <mat-label>Staff</mat-label>
      <mat-select [(ngModel)]="selectedSkills" multiple>
        <mat-option>
          <ngx-mat-select-search [placeholderLabel]="'Search Here...'" [noEntriesFoundLabel]="'Record not found'"
            [formControl]="staffSeachInput"></ngx-mat-select-search>
        </mat-option>
        <mat-select-trigger>
          {{displayText()}}
        </mat-select-trigger>
        <mat-option class="es-options" *ngFor="let eligibleStaff of filtered_eligibleStaffsList" [value]="eligibleStaff.worker.workerCode">
          <!-- {{option.worker.name}} -->
          <ng-container [ngTemplateOutlet]="eligibleStaff_temp" [ngTemplateOutletContext]="{eligibleStaff: eligibleStaff}">
          </ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-template #eligibleStaff_temp let-eligibleStaff="eligibleStaff">
      <div class="row es-container">
<!-- 
        <ng-template [ngIf]="loggedIn_userType === 'Customer'" [ngIfElse]="DefaultView_Template"> 
        <div class="col-1">
          <img class="staff-image me-2"  width="30" height="30" [alt]="eligibleStaff.worker.name"
                      [src]="img_serverPath + eligibleStaff.worker.workerLogo"
                      onerror="this.src='assets/common_images/profile.jpg';" />
        </div>
        <div class="col-5">
          <p class="txt-lbl mb-0 ms-2 cursor-pointer pointer">{{eligibleStaff.worker.name | capitalize}}</p>
          <small class="email mb-0 ms-2">{{eligibleStaff.worker.email}}</small>
        </div>
       </ng-template>

       <ng-template #DefaultView_Template>
        <div class="col-1">
          <img class="staff-image me-2"  width="30" height="30" [alt]="eligibleStaff.worker.name"
                      [src]="img_serverPath + eligibleStaff.worker.workerLogo"
                      onerror="this.src='assets/common_images/profile.jpg';" />
        </div>
        <div class="col-5">
          <p class="txt-lbl mb-0 ms-2 cursor-pointer pointer">{{eligibleStaff.worker.name | capitalize}}</p>
          <small class="email mb-0 ms-2">{{eligibleStaff.worker.email}}</small>
        </div>
       </ng-template> -->

       <div class="col-1" *canCustomerView="'profile_pic'">
        <img class="staff-image me-2"
        loading="lazy"
          width="30"
           height="30"
            [alt]="eligibleStaff.worker.name"
          [src]="img_serverPath + eligibleStaff.worker.workerLogo"
          onerror="this.src='assets/common_images/profile.jpg';" />
      </div>

      <div class="col-5" >
        <p class="txt-lbl mb-0 ms-2 cursor-pointer pointer">{{eligibleStaff.worker.name | capitalize}}</p>
        <small *canCustomerView="'email'" class="email mb-0 ms-2">{{eligibleStaff.worker.email}}</small>
      </div>

        <div class="col-3">
          <p class="txt-lbl mb-0">Distance</p>
          <small>{{eligibleStaff.distanceText ? eligibleStaff.distanceText : 'NA'}}</small>
        </div>

        <!-- <div class="col" *ngIf="isDrivingTime"> -->
          <div class="col-3">
          <p class="txt-lbl mb-0">Duration</p>
          <small>{{eligibleStaff.durationText ? eligibleStaff.durationText : 'NA'}}</small>
        </div>
      </div>
    </ng-template>

  </ng-container>
  <ng-template #noData>
    <div class="row d-flex align-items-center spinner spinner-sm" *ngIf="showSpinner">
      <div class="col-lg-2 text-end">
        <mat-spinner></mat-spinner>
      </div>
      <div class="col-lg-8 text-start"><span>Please Wait, Getting Staff ...</span></div>
    </div>
    <div *ngIf="!showSpinner">
      <mat-form-field appearance="outline" floatLabel="always" class="example-full-width custom-search">
        <mat-label>Staff</mat-label>
        <mat-select>
          <mat-option disabled>No Staff Available</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-template>
</div>

<div class="row mt-3">
  <div class="col-lg-12 text-end">
    <button mat-button class="me-3" (click)="closePopup()">Cancel</button>
    <button mat-raised-button color="primary" (click)="sendNotification()">Send Notification</button>
  </div>
</div>
</div>