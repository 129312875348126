<div class="col-md-12">
  <div class="row">
    <div class="col-md-6 align-self-center">
      <h6 class="dialog-header pt-2">Assign Job</h6>
    </div>
    <div class="col-md-6">
      <div class="row justify-content-end">
        <div class="w-100 d-flex">
          <input type="text" class="form-control w-100 me-4" id="search-patient" autocomplete="off"
             placeholder="Search By Staff" name="search-patient" 
             [(ngModel)]="searchText"
             (keyup)="applyFilter($event.target.value)">         
        <button class="btn-close cursor-pointer" (click)="closePopup()"></button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col text-end me-3">
      <span *ngIf="required_staffs" class="me-3 font-14">Required Staff: <b>{{required_staffs}}</b></span>
      <span *ngIf="selectedStaff.length > 0" class="font-14"> Selected Staff: <b>{{selectedStaff.length}}</b></span>
    </div>
 </div>

  <div class="row">
    <ul class="{{filtered_eligibleStaffsList.length > 4 ? 'poupup-content' : ''}}"
      *ngIf="filtered_eligibleStaffsList.length > 0">
      <li class="mt-2" *ngFor="let element of filtered_eligibleStaffsList; index as idx">
        <div class="row">
          <div class="col-md-1 text-center align-self-center ">
            <!-- <mat-checkbox 
            [checked]="isChecked(element)"
             (click)="onChangeSetStaffs($event, element, index )"> </mat-checkbox> -->
             <input type="checkbox" class="chbx_staff" [checked]="isChecked(element)"
             (click)="onChangeSetStaffs($event, element, index )">
          </div>
          <div class="col-md-11 font-card">
            <mat-card class="example-card">
              <mat-card-content>
                <div class="row">
                  <div class="col avatarBox">
                    <img class="staff-image me-2" [alt]="element.worker.name"
                      [src]="img_serverPath + element.worker.workerLogo"
                      onerror="this.src='assets/common_images/profile.jpg';" />
                  </div>
                  <div class="col-6 staff-hover">
                    <p class=" mb-0 ms-2 cursor-pointer pointer">
                      <span><profile-avatar-with-name [userDetail]="element.worker" [isRowNameWithLogo]="false" [popoverShowFor]="'staff'" [isProfileButtonEnable]="true" [isChatButtonEnable]="true"></profile-avatar-with-name></span>
                      </p>
                    <small class="email mb-0 ms-2">{{element.worker.email}}</small>
                  </div>

                  <div class="col">
                    <p class="mb-0">Distance</p>
                    <small>{{element.distanceText ? element.distanceText : 'NA'}}</small>
                  </div>

                  <div class="col" *ngIf="isDrivingTime">
                    <p class="mb-0">Duration</p>
                    <small>{{element.durationText ? element.durationText : 'NA'}}</small>
                  </div>

                </div>
              </mat-card-content>
            </mat-card>

          </div>

        </div>
      </li>
    </ul>

    <div class="row d-flex align-items-center spinner spinner-sm" *ngIf="showSpinner && filtered_eligibleStaffsList.length === 0">
      <div class="col-lg-1 text-end">
        <mat-spinner></mat-spinner>
      </div>
      <div class="col-lg-8 text-start"><span>Please Wait, Getting Staff ...</span></div>
    </div>

    <div class="row" *ngIf="!showSpinner && filtered_eligibleStaffsList.length === 0">
      <div>
        <span class="text-danger" [class.cursor-pointer]="failedWorkerResponseList && failedWorkerResponseList?.length > 0" (click)="failedWorkerResponseList && failedWorkerResponseList?.length > 0 && showFailedRes()">No Staff Available</span>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-lg-12 text-end">
      <button class="me-3" mat-button (click)="closePopup()">Cancel</button>
      <button *ngIf="filtered_eligibleStaffsList.length > 0" mat-raised-button color="primary"
        (click)="AssignJobToStaff()">Assign</button>
    </div>
  </div>
</div>