
export enum DateFormat 
{
    YYYY_MM_DD = 'YYYY-MM-DD',
    DD_MM_YYYY = 'DD-MM-YYYY',
    MM_DD_YYYY = 'MM-DD-YYYY',
    HH_MM_SS   = 'HH:mm:ss'
}

export type DateFormatType = DateFormat[keyof DateFormat];


 export const web_app_staffworks_logo =  'assets/staffwork_logo.png';
