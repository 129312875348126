    <div class="scheduleStaff-Card">

          <div class="d-flex">

            <div style="width: 20%;">
              <ng-template [ngIf]="user_type!== 'Staff' && user_type!== 'Customer'" [ngIfElse]="else_worker">
                <profile-avatar-with-name class="profileSpace" [lableTxt]="'Staff Name'" [userDetail]="scheduleStaff_Input_Data"
                  [isRowNameWithLogo]="true" [popoverShowFor]="'staff'" [isProfileButtonEnable]="true"
                  [isChatButtonEnable]="true"></profile-avatar-with-name>
              </ng-template>
              <ng-template #else_worker>
                <profile-avatar-with-name class="profileSpace" [lableTxt]="'Staff Name'" [userDetail]="scheduleStaff_Input_Data"
                  [isRowNameWithLogo]="true" [loggedIn_userType]="user_type" [popoverShowFor]="'staff'"
                  [isProfileButtonEnable]="true" [isChatButtonEnable]="true">
                </profile-avatar-with-name>
              </ng-template>
            
            </div>

            <ng-template [ngIf]="showMoileNo">
            <div style="width: 15%;">
              <small>Mobile No </small><br>
              <p>{{scheduleStaff_Input_Data.mobileNo | mobileNoFormate}}</p>
            </div>
            </ng-template>

            <!-- <ng-template [ngIf]="showMoreControls"> -->

              <div class="text-capitalize position-relative" style="width: 10%;">
                <small>Status </small><br>
                <!-- <p class="
                   {{  scheduleStaff_Input_Data.status?.toLowerCase() === 'on job' ? 'badge bg-warning' : '' }} 
                   {{  scheduleStaff_Input_Data.status?.toLowerCase() === 'accepted' ? 'badge bg-success' : '' }} 
                   {{  scheduleStaff_Input_Data.status?.toLowerCase() === 'completed' ? 'badge bg-primary' : '' }} 
                   {{  scheduleStaff_Input_Data.status?.toLowerCase() === 'on break' ? 'badge bg-danger' : '' }} 
                   {{ (scheduleStaff_Input_Data.status?.toLowerCase() === 'absent' || scheduleStaff_Input_Data.status?.toLowerCase() === 'no show' ) ? 'badge bg-danger' : '' }} 
                   "> -->

                   <p [class]="scheduleStaff_Input_Data.status_badge"> {{scheduleStaff_Input_Data.status }}</p>

                  <mat-icon
                   class="cls-comment"
                   *ngIf="(scheduleStaff_Input_Data.status?.toLowerCase() === 'absent' || scheduleStaff_Input_Data.status?.toLowerCase() === 'no show')
                          && scheduleStaff_Input_Data?.adminComment"
                    (click)="showAdminComment(scheduleStaff_Input_Data?.adminComment)"
                   > 
                   comment
                  </mat-icon>

              </div>

              <!-- <ng-template [ngIf]="scheduleStaff_Input_Data.isAllowClockin === true"> -->

                <!-- <ng-template [ngIf]="scheduleStaff_Input_Data.status !== 'accepted'"> -->

                  <!-- <ng-template [ngIf]="scheduleStaff_Input_Data.inBreak"> -->
                    <div style="width: 10%;">
                      <small>Break Status </small><br>
                      <ng-template [ngIf]="scheduleStaff_Input_Data.inBreak" [ngIfElse]="notInBreak">
                      <div style="color: blue;">{{ scheduleStaff_Input_Data.breakStart_timer$ | async }}</div>
                      <div class="break" *ngIf="scheduleStaff_Input_Data.breakDurationMin">{{scheduleStaff_Input_Data.breakDurationMin+' mins break'}}</div>
                    </ng-template>
                    <ng-template #notInBreak><spna> -- --</spna></ng-template>
                    </div>
                  

                <!-- </ng-template> -->

                <!-- <ng-template [ngIf]="scheduleStaff_Input_Data.clockinoutDetailList.length > 0"> -->

                  <div class=" c_IO_tbl" style="width: 20%;">
                    <table>
                      <thead>
                        <tr>
                          <th>Clock In</th>
                          <th>Clock Out</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-template [ngIf]="scheduleStaff_Input_Data.clockinoutDetailList.length > 0" [ngIfElse]="noClockInOutData"> 

                        <tr *ngFor="let c_IO of scheduleStaff_Input_Data.clockinoutDetailList">
                          <td> <small>{{c_IO.clockInTime ? c_IO.clockInTime : '--'}}</small></td>
                          <td [matTooltip]="c_IO.isNextDate ? c_IO.clockInDateTime +' - '+ c_IO.clockOutDateTime : ''">
                           <small>{{c_IO.clockOutTime ? c_IO.clockOutTime : '--'}}</small>

                            <span class="half-moon" *ngIf="c_IO.isNextDate">
                              <img src="../../../assets/icons/svg/half-moon.svg" alt="">
                            </span>
                          </td>

                          <td>
                             <div class="d-flex">
                              
                              <img matTooltip="Clock-in by staff" matTooltipPosition="above" *ngIf="c_IO?.clockinViaMobile" class="pe-1" src="../../../assets/images/mobile-icon.svg" >

                              <img matTooltip="Clock-in by admin" matTooltipPosition="above" *ngIf="!c_IO?.clockinViaMobile" class="pe-1" src="../../../assets/images/web-icon.svg" >

                            <!-- <ng-template [ngIf]="scheduleStaff_Input_Data.status?.toLowerCase() != 'no show' &&  scheduleStaff_Input_Data.status?.toLowerCase() != 'absent'">
                              <ng-template [ngIf]="scheduleStaff_Input_Data.isPassedEndTime && !c_IO.isApproved">
                              <ng-template [ngIf]="allowToUpdateTime">
                              <mat-icon class="update-time-edit-icon" (click)="updateClockInOutTime(c_IO, scheduleStaff_Input_Data)">edit</mat-icon>
                            </ng-template>
                            </ng-template>
                          </ng-template> -->

                          <ng-template [ngIf]="user_type === 'Customer'" [ngIfElse]="adminView">
                            <mat-icon
                            *ngIf=" scheduleStaff_Input_Data.status?.toLowerCase() !== 'no show' 
                             && scheduleStaff_Input_Data.status?.toLowerCase() !== 'absent' 
                             && scheduleStaff_Input_Data.isPassedEndTime 
                             && !c_IO?.isApproved"
                             class="update-time-edit-icon me-2" 
                             (click)="ask_if_timecard_already_updated(c_IO, scheduleStaff_Input_Data)"
                             >
                             edit
                            </mat-icon>                            
                          </ng-template>
                          
                          <ng-template #adminView>
                            <ng-template [ngIf]="scheduleStaff_Input_Data.status?.toLowerCase() !== 'no show' 
                                                      && scheduleStaff_Input_Data.status?.toLowerCase() !== 'absent' 
                                                      && scheduleStaff_Input_Data.isPassedEndTime 
                                                      && allowToUpdateTime">
                      <mat-icon class="update-time-edit-icon" (click)="ask_if_timecard_already_updated(c_IO, scheduleStaff_Input_Data)">edit</mat-icon>
                      </ng-template>
                          </ng-template>
 
                            <!-- // previous applied condition -->

                            <!-- <ng-template [ngIf]="scheduleStaff_Input_Data.status?.toLowerCase() != 'no show' &&  scheduleStaff_Input_Data.status?.toLowerCase() != 'absent'">
                            <ng-template [ngIf]="c_IO.clockInTime && c_IO.clockOutTime && !c_IO.isApproved" [ngIfElse]="check_end_time_passed">
                            <ng-template [ngIf]="allowToUpdateTime">
                            <mat-icon class="update-time-edit-icon" (click)="updateClockInOutTime(c_IO, scheduleStaff_Input_Data)">edit</mat-icon>
                          </ng-template>
                          </ng-template>
                        </ng-template>

                          <ng-template #check_end_time_passed>
                            <ng-template [ngIf]="scheduleStaff_Input_Data.status?.toLowerCase() != 'no show' &&  scheduleStaff_Input_Data.status?.toLowerCase() != 'absent'">
                            <ng-template [ngIf]="allowToUpdateTime">
                            <mat-icon *ngIf="scheduleStaff_Input_Data.isPassedEndTime && !c_IO.isApproved"
                             class="update-time-edit-icon" (click)="updateClockInOutTime(c_IO, scheduleStaff_Input_Data)">edit</mat-icon>
                             </ng-template>
                             </ng-template>
                          </ng-template> -->

                        </div>
                          </td>

                          <!-- <td *ngIf="scheduleStaff_Input_Data.isPassedEndTime && !c_IO.isApproved"><mat-icon class="update-time-edit-icon" (click)="updateClockInOutTime(c_IO, scheduleStaff_Input_Data)">edit</mat-icon></td> -->
                        </tr>
                        </ng-template>

                        <ng-template #noClockInOutData>
                          <tr>
                            <td>--</td>
                            <td>--</td>


                            <ng-template [ngIf]="user_type === 'Customer'" [ngIfElse]="adminView">
                              <mat-icon
                              *ngIf=" scheduleStaff_Input_Data.status?.toLowerCase() !== 'no show' 
                              && scheduleStaff_Input_Data.status?.toLowerCase() !== 'absent' 
                              && scheduleStaff_Input_Data.isPassedEndTime 
                              && !c_IO?.isApproved"
                               class="update-time-edit-icon" 
                               (click)="ask_if_timecard_already_updated(c_IO, scheduleStaff_Input_Data)"
                               >
                               edit
                              </mat-icon>                              
                            </ng-template>
                            
                            <ng-template #adminView>
                              <ng-template [ngIf]="scheduleStaff_Input_Data.status?.toLowerCase() !== 'no show' 
                              && scheduleStaff_Input_Data.status?.toLowerCase() !== 'absent' 
                              && scheduleStaff_Input_Data.isPassedEndTime 
                              && allowToUpdateTime"
                               >
                       <mat-icon class="update-time-edit-icon" (click)="ask_if_timecard_already_updated(c_IO, scheduleStaff_Input_Data)">edit</mat-icon>
                        </ng-template>
                            </ng-template>

                            <!-- <ng-template [ngIf]="allowToUpdateTime">
                              <ng-template [ngIf]="scheduleStaff_Input_Data.status?.toLowerCase() != 'no show' &&  scheduleStaff_Input_Data.status?.toLowerCase() != 'absent'">
                             <td *ngIf="scheduleStaff_Input_Data.isPassedEndTime" class="p-0">
                              <mat-icon class="update-time-edit-icon" (click)="ask_if_timecard_already_updated(c_IO, scheduleStaff_Input_Data)">edit</mat-icon>
                            </td>
                            </ng-template>
                            </ng-template> -->
                          </tr>
                        </ng-template>

                      </tbody>
                    </table>
                  </div>


                  <div style="width: 15%;">
                    <small>Break Time </small><br>
                    <ng-template [ngIf]=" scheduleStaff_Input_Data.breakDetailList && scheduleStaff_Input_Data.breakDetailList.length > 0 " [ngIfElse]="noBreaks">
                     <div class="row" style="font-size: 11px;" *ngFor="let break of scheduleStaff_Input_Data.breakDetailList">
                      <div class="col w-auto text-capitalize maxW_auto"> {{break.breakType}} </div>
                      <div class="col w-auto"> {{break.duration}}&nbsp;min {{break.paidStatus ? '$' : ''}}</div>
                    </div>

                   </ng-template>
                  <ng-template #noBreaks><spna> -- --</spna></ng-template>
                  </div>

                <!-- </ng-template> -->

                <!-- <div class="col">
                  <ng-template [ngIf]="scheduleStaff_Input_Data.clockInOutButton && scheduleStaff_Input_Data.clockInOutButton !== null">
                    <button class="btn-jobcard" [style.color]="job_shortData_Modal?.jobType_Data?.jobColor"
                      (click)="saveCheckInDetail(scheduleStaff_Input_Data)">
                      {{scheduleStaff_Input_Data.clockInOutButton}}
                    </button>
                  </ng-template>
                </div> -->

              <!-- </ng-template> -->

            <!-- </ng-template> -->

           <ng-template [ngIf]="showActionButtons">
            <div class="text-start" style="width: 20%;">

            <ng-template [ngIf]="scheduleStaff_Input_Data.clockInOutButton && scheduleStaff_Input_Data.clockInOutButton !== null">
              <ng-container *ngIf="scheduleStaff_Input_Data.clockInOutButton === 'Clock-Out'">
                <button class="btn-jobcard me-1" [style.color]="job_shortData_Modal?.jobType_Data?.jobColor"
                (click)="saveClockOutDetail(scheduleStaff_Input_Data)">
                {{scheduleStaff_Input_Data.clockInOutButton}}
              </button>
              </ng-container>
              <ng-container *ngIf="scheduleStaff_Input_Data.clockInOutButton !== 'Clock-Out'">
                <button class="btn-jobcard me-1" [style.color]="job_shortData_Modal?.jobType_Data?.jobColor"
                (click)="saveCheckInDetail(scheduleStaff_Input_Data)">
                {{scheduleStaff_Input_Data.clockInOutButton}}
              </button>
              </ng-container>              
            </ng-template>

            <ng-template [ngIf]="!scheduleStaff_Input_Data.isPassedEndTime && user_type !== 'Customer'">
              <ng-template [ngIf]="scheduleStaff_Input_Data.status === 'accepted'">

                 
                  <button class="btn-jobcard me-1" [style.color]="job_shortData_Modal?.jobType_Data?.jobColor"
                    (click)="changeStaff(scheduleStaff_Input_Data.appointmentsDurationCode)">
                    Change
                  </button>

                  <button class="btn-jobcard" [style.color]="job_shortData_Modal?.jobType_Data?.jobColor"
                    (click)="UnAssignStaff(scheduleStaff_Input_Data.workerName, scheduleStaff_Input_Data.appointmentsDurationCode)">
                    UnAssign
                  </button>
 
              </ng-template>
            </ng-template>

            </div>
          </ng-template>

          </div>
          <!-- <div *ngIf="scheduleStaff_Input_Data.appointmentsDurationCode" class="row div-border mb-1 mt-1"></div> -->
        </div>
  
 

<ng-template #assign_popup>
    <!-- <mat-card>
        <mat-card-content> -->
    <div class="row  ">
      <div class="col-md-6 align-self-center">
        <h6 class="dialog-header pt-2">Assign Job</h6>
      </div>
      <div class="col-md-6">
        <div class="row justify-content-end">
          <button class="btn-close cursor-pointer" (click)="closePopup()"></button>
        </div>
      </div>
    </div>
  
    <div class="row">
      <ul class="{{filtered_eligibleStaffsList.length > 4 ? 'poupup-content' : ''}}"
        *ngIf="filtered_eligibleStaffsList.length > 0">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedStaff">
          <li class="mt-2" *ngFor="let element of filtered_eligibleStaffsList">
            <div class="row">
              <div class="col-md-1 text-center align-self-center ">
                <mat-radio-button class="ms-3" [checked]="element.isAssigned" [value]="element"></mat-radio-button>
              </div>
              <div class="col-md-11 font-card">
                <mat-card class="example-card">
                  <mat-card-content>
                    <div class="row">
                      <!-- <div class="col-md-2">
                        <img class="staff-image me-2"
                           [alt]="element.worker.name" [src]="img_serverPath + element.worker.workerLogo"
                          onerror="this.src='assets/common_images/profile.jpg';" />
                      </div>
                      <div class="col-md-5">
                        <p class="name mb-0 ms-2">{{element.worker.name}}</p>
                        <small class="email mb-0 ms-2">{{element.worker.email}}</small>
                      </div> -->

                      <div class="col avatarBox" *canCustomerView="'profile_pic'">
                        <img class="staff-image me-2" [alt]="element.worker.name"
                          [src]="img_serverPath + element.worker.workerLogo"
                          onerror="this.src='assets/common_images/profile.jpg';" />
                      </div>
                      <div class="col-6 staff-hover">
                        <p class=" mb-0 ms-2 cursor-pointer pointer">

                          <span *ngIf="user_type !== 'Customer'"><profile-avatar-with-name [userDetail]="element.worker" [isRowNameWithLogo]="false" [popoverShowFor]="'staff'" [isProfileButtonEnable]="true" [isChatButtonEnable]="true"></profile-avatar-with-name></span>

                          <span *ngIf="user_type === 'Customer'">
                            {{element.worker.name | capitalize}}
                          </span>

                        </p>
                        <small *canCustomerView="'email'" class="email mb-0 ms-2">{{element.worker.email}}</small>
                      </div>
    
                      <div class="col">
                        <p class="mb-0">Distance</p>
                        <small>{{element.distanceText ? element.distanceText : 'NA'}}</small>
                      </div>
    
                      <div class="col" *ngIf="isDrivingTime">
                        <p class="mb-0">Duration</p>
                        <small>{{element.durationText ? element.durationText : 'NA'}}</small>
                      </div>
  
                    </div>
  
                  </mat-card-content>
                </mat-card>
  
              </div>
  
            </div>
          </li>
        </mat-radio-group>
  
      </ul>
  
      <div class="row d-flex align-items-center spinner" *ngIf="showSpinner && filtered_eligibleStaffsList.length === 0">
        <div class="col-lg-1 text-end">
          <mat-spinner></mat-spinner>
        </div>
        <div class="col-lg-8 text-start"><span>Please Wait, Getting Staff ...</span></div>
      </div>
  
      <div class="row" *ngIf="!showSpinner && filtered_eligibleStaffsList.length === 0">
        <div>
          <span class="text-danger">No Staff Available</span>
        </div>
      </div>
    </div>
  
  
    <div class="row mt-3">
      <div class="col-lg-12 text-end">
        <button class="me-3" mat-button (click)="closePopup()">Cancel</button>
        <button *ngIf="filtered_eligibleStaffsList.length > 0" mat-raised-button color="primary"
          (click)="AssignStaff()">Assign</button>
  
      </div>
    </div>
  
  </ng-template>

 
  <ng-template #update_clock_in_out_time>
    <!-- <div class="row">
      <div class="col-md-6 align-self-center">
      </div>
      <div class="col-md-6">
        <div class="row justify-content-end">
          <button class="btn-close cursor-pointer" (click)="closePopup()"></button>
        </div>
      </div>
    </div> -->
  
    <div class="card-body">

    <!-- <app-update-clock-in-out-time
    [missed_clock_in_out_inputs]="missed_clock_in_out_inputs"
    (update_parent)="refreshList($event)"
    ></app-update-clock-in-out-time> -->

    <app-update-clock-in-out-time
    [missed_clock_in_out_inputs]="missed_clock_in_out_inputs"
    [allow_to_mark_staff_absent_or_notshow]="allowToMarkStaffAbsent"
    [eligibleStaff]="eligible_staffs_for_clock_in_out"
    (getFormData)="update_missed_clock_in_out_data($event)"
    (absent_Or_NoShow)="staff_absent_Or_NoShow($event)"
    ></app-update-clock-in-out-time>

    </div>
  
   </ng-template>

   
   <ng-template #comment_popup>
      <div class="row  ">
      <div class="col-md-6 align-self-center">
        <h6 class="dialog-header pt-2">Comment</h6>
      </div>
      <div class="col-md-6">
        <div class="row justify-content-end">
          <button class="btn-close cursor-pointer" mat-dialog-close></button>
        </div>
      </div>
    </div>
  
    <div class="card-body">
    <div class="row sec-comment">
   {{comment_text}}
    </div>
    </div>

  </ng-template>